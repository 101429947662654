<template>
    <div class="liveRoom">
        <div id="ppt" style="width:500px;height:300px;"></div>
        <div id="player" style="width:500px;height:300px;"></div>
    </div>
</template>

<script>
export default {
    name:'liveRoom',
    data(){
        return{
            isUserInfo:false
        }
    },
    mounted(){
        var liveSdk = new PolyvLiveSdk({
            channelId: channelId,
            sign: sign, // 频道验证签名
            timestamp: timestamp, // 毫秒级时间戳
            appId: appId, // polyv 后台的appId
            user: {
                userId: userId,
                userName: 'polyv-test',
                pic: '//livestatic.videocc.net/assets/wimages/missing_face.png'
            }
        });
        // 监听频道信息并初始化播放器
        liveSdk.on(PolyvLiveSdk.EVENTS.CHANNEL_DATA_INIT, (event, data) => {
            liveSdk.setupPlayer({
                pptEl: '#ppt',
                el: '#player',
                type: 'auto'
            });
        });  
    },
    methods:{
        // 父传子
        getTodos(e){
            console.log(e)
            this.isUserInfo=e
        },
    }
}
</script>

<style>

</style>